import React from 'React'
import '../../../node_modules/react-vis/dist/style.css'
import {
  FlexibleXYPlot,
  LineMarkSeries,
  GradientDefs,
  AreaSeries,
} from 'react-vis'
import { maxBy } from 'lodash'
import { colors } from '../../styles/vars'

const LineChart = () => {
  const data = [
    { x: 0, y: 8 },
    { x: 1, y: 5 },
    { x: 2, y: 4 },
    { x: 3, y: 5 },
    { x: 4, y: 3 },
    { x: 5, y: 6 },
    { x: 6, y: 6.5 },
    { x: 7, y: 4 },
    { x: 8, y: 3 },
    { x: 9, y: 6 },
    { x: 10, y: 7 },
    { x: 11, y: 5 },
    { x: 12, y: 8 },
    { x: 13, y: 9 },
    { x: 14, y: 14 },
    { x: 15, y: 13 },
    { x: 16, y: 9 },
    { x: 17, y: 8 },
    { x: 18, y: 5 },
    { x: 19, y: 4 },
    { x: 20, y: 9 },
    { x: 21, y: 8 },
    { x: 22, y: 8 },
    { x: 23, y: 6 },
    { x: 24, y: 5 },
    { x: 25, y: 6 },
    { x: 26, y: 8 },
    { x: 27, y: 12 },
    { x: 28, y: 15 },
    { x: 29, y: 19 },
    { x: 30, y: 16 },
    { x: 31, y: 14 },
    { x: 32, y: 10 },
    { x: 33, y: 11 },
  ]
  return (
    <div
      style={{
        height: '50vh',
        width: '100%',
        padding: '0vw',
        paddingTop: '0vw',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        position: 'absolute',
        minWidth: '950px',
        bottom: '0vh',
        left: 0,
        zIndex: 2,
      }}
    >
      <FlexibleXYPlot
        style={{ width: '100%' }}
        yDomain={[0, maxBy(data, (o) => o.y).y]}
        margin={{
          left: 0,
          right: 0,
        }}
      >
        <GradientDefs>
          <linearGradient id='CoolGradient' x1='0' x2='0' y1='0' y2='1'>
            <stop
              offset='0%'
              stopColor={colors.lightBlue}
              stopOpacity={0.2}
            />
            <stop
              offset='100%'
              stopColor={colors.lightBlue}
              stopOpacity={0}
            />
          </linearGradient>
          <radialGradient id='CoolGradientRad' x1='0' x2='0' y1='0' y2='1'>
            <stop
              offset='0%'
              stopColor={colors.lightBlue}
              stopOpacity={1}
            />
            <stop
              offset='23%'
              stopColor={colors.lightBlue}
              stopOpacity={1}
            />
            <stop
              offset='100%'
              stopColor={colors.lightBlue}
              stopOpacity={1}
            />
          </radialGradient>
        </GradientDefs>
        <AreaSeries
          animation
          data={data}
          color={'url(#CoolGradient)'}
          curve={'curveBasis'}
          lineStyle={{
            strokeWidth: 0,
            opacity: 0,
            stroke: colors.green,
          }}
        />
        <LineMarkSeries
          data={data}
          size={4}
          markStyle={{
            strokeWidth: 3,
            stroke: colors.lightBlue,
            fill: '#fff',
            opacity: 1,
          }}
          lineStyle={{
            strokeWidth: 3,
            opacity: 1,
            stroke: colors.lightBlue,
          }}
          animation
          curve={'Linear'}
        />
      </FlexibleXYPlot>
    </div>
  )
}

export default LineChart
