import styled from 'styled-components'
import { colors } from './vars'

const StartContainer = styled.div`
  max-width: 90vw;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 3;
  min-height: 90vh;
  .sell-copy {
    margin: 5vw 0;
    max-width: 48rem;
    position: relative;
    z-index: 99;
    h1 {
      position: relative;
      margin-bottom: 6rem;
      font-size: 5rem;
    }
    a {
      font-size: 1.5rem;
      line-height: 1.1em;
      color: ${colors.red};
      font-weight: 700;
      text-decoration: none;
      border-bottom: 3px solid ${colors.red};
      padding-bottom: 0.1rem;
      transition: all 0.3s ease;
      margin-right: 2rem;
      &:hover {
        border-bottom: 3px solid ${colors.red};
        padding-bottom: 0.3rem;
      }
    }
  }
`
const Illustrations = styled.div`
  max-width: 100vw;
  overflow: hidden;
`
export { StartContainer, Illustrations }
